import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ImageHelper {

    compressImage(file: File, maxWidth = 1024, maxHeight = 768, quality = 0.9): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = (event: any) => {
                const img = new Image();
                img.src = event.target.result;

                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    // Mantener la relación de aspecto
                    if (width > maxWidth || height > maxHeight) {
                        if (width > height) {
                            height = Math.round((height *= maxWidth / width));
                            width = maxWidth;
                        } else {
                            width = Math.round((width *= maxHeight / height));
                            height = maxHeight;
                        }
                    }

                    // Redimensionar la imagen
                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    if (ctx) {
                        ctx.drawImage(img, 0, 0, width, height);

                        // Convertir el canvas a un Blob de imagen comprimida
                        canvas.toBlob(
                            (blob) => {
                                if (blob) {
                                    const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
                                    resolve(compressedFile);
                                }
                            },
                            'image/jpeg', // Formato de compresión (puede ser 'image/png' también)
                            quality // Calidad de la compresión (0.7 = 70%)
                        );
                    } else {
                        reject(new Error('No se pudo obtener el contexto del canvas'));
                    }
                };
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    }
}
